// config.js

const config = {
  projectId: 'miloubar-0000',
  domain: 'https://miloubar.tangoai.co',
  
  sidebarPages: {
    admin: ['Dashboard', 'Reports', 'Playground', 'Optimization'],
    user: ['Dashboard', 'Playground']
  },
  
  exemptedColumns: [
    'nir_number',
    'product_code',
    'destination_cell',
    'shape_code',
    'press'
  ]
};

export default config;